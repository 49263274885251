import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Upload } from '../interfaces/upload';
import { File as NativeFile, FileEntry } from '@ionic-native/file/ngx';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: AngularFireStorage,
    private nativeFile: NativeFile
  ) {}

  uploadFile(file) {

    console.log('llega uploadFile');
    const fileType = this.getFileType(file.type);
    const fileName = Math.floor((Math.random() * 1000000) + 1) + '_' + file.name;
    const fileRef = this.storage.ref(`${fileType}/${fileName}`);

    // eslint-disable-next-line prefer-const
    let newUpload: Upload = {
      fileData: file,
      fileName,
      fileRef,
      fileType,
      finished: false,
      task: fileRef.put(file)
    };

    return newUpload;
  }

  uploadAudioFile(file, _callback) {

    console.log('uploadAudioFile', file);

    const fileType = 'audio';
    const fileName = Math.floor((Math.random() * 1000000) + 1) + '_' + file.name;
    const fileRef = this.storage.ref(`${fileType}/${fileName}`);

    setTimeout(()=>{

      this.getBlobFromFileEntry(file.entry)
        .then((bufferArr: ArrayBufferLike)=> {
          const blob = new Blob([new Uint8Array(bufferArr)], { type: file.entry.type});
          // eslint-disable-next-line prefer-const
          let newUpload: Upload = {
            fileData: null,
            fileName: file.name,
            fileRef,
            fileType,
            finished: false,
            task: fileRef.put(blob)
          };

          _callback(newUpload);
        },
        (err) => {
          console.log(err);
        });
    }, 300);
  }

  uploadProfilePic(dataUrl) {

    const fileName = Math.floor((Math.random() * 1000000) + 1) + '_profile.jpg';
    const fileRef = this.storage.ref(`profile/${fileName}`);

    return fileRef.putString(dataUrl, 'data_url');
  }

  async getBlobFromFileEntry(fileEntry: FileEntry) {

    const reader = this.getOriginalFileReader();
    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            if (reader.result !== undefined || reader.result !== null) {
                resolve(reader.result);
            }
            else if (reader.error !== undefined || reader.error !== null) {
                reject(reader.error);
            }
            else {
                reject({ code: null, message: 'READER_ONLOADEND_ERR' });
            }
        };
        fileEntry.file((file) => {
            reader.readAsArrayBuffer(file);
        }, (error) => {
            reject(error);
        });
    });
  }

  getOriginalFileReader(): FileReader {
    const fileReader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
    return zoneOriginalInstance || fileReader;
  }

  getFileType(mimeType) {
    let baseType: string;

    switch(mimeType) {
      case 'video/x-msvideo':
      case 'video/mp4':
      case 'video/mpeg':
      case 'video/ogg':
      case 'video/mp2t':
      case 'video/webm':
      case 'video/3gpp':
      case 'video/3gpp':
      case 'video/3gpp2':
      case 'video/mov':
      case 'video/quicktime':
        baseType = 'video';
        break;
      case 'image/bmp':
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
      case 'image/svg+xml':
      case 'image/tiff':
      case 'image/webp':
        baseType = 'image';
        break;
      case 'audio/midi':
      case 'audio/x-midi':
      case 'audio/mpeg':
      case 'audio/aac':
      case 'audio/ogg':
      case 'audio/opus':
      case 'audio/mpeg':
      case 'audio/wav':
      case 'audio/webm':
      case 'audio/3gpp':
      case 'audio/3gpp2':
        baseType = 'audio';
        break;
      default:
        baseType = 'document';
        break;
    }

    return baseType;
  }

}
