import { Injectable } from '@angular/core';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private restService: RestService) {
  }

  getUserChats() {
    return this.restService.makeHttpRequest('chat', 'getChats', {}, 'get');
  }

  getAssistanceChats() {
    return this.restService.makeHttpRequest('chat', 'getAssistanceChats', {}, 'get');
  }

  getChat(chatId) {
    return this.restService.makeHttpRequest('chat', `get/${chatId}`, null, 'get');
  }

  getChatMessages(chatId, limit, offset) {
    return this.restService.makeHttpRequest('chat', 'getMessages', {
      chatId,
      limit,
      offset
    }, 'post');
  }

  createBotMessage(chatId, type, body) {
    return this.restService.makeHttpRequest('chat-bot', 'createMessage', {
      chatId,
      type,
      body
    }, 'post');
  }
  restartHistory(chatId) {
    return this.restService.makeHttpRequest('chat-bot', 'restartHistory', {
      chatId
    }, 'post');
  }
  createMessage(chatId, type, body) {
    return this.restService.makeHttpRequest('chat', 'createMessage', {
      chatId,
      type,
      body
    }, 'post');
  }

  createChat(contactId, isApproved) {
    return this.restService.makeHttpRequest('chat', 'createChat', {
      contactId,
      isApproved
    }, 'post');
  }

  getMessageDescription(msg: any) {
    let res: string;

    switch(msg.type) {
      case 'text':
      case 'system':
        const txt = msg.body.replace(/(<([^>]+)>)/gi, '');
        res = txt ? (txt.length <= 100 ? txt : txt.substring(0, 97) + '...') : '';
        break;
      case 'audio':
        res = 'Ha enviado un audio';
        break;
      case 'video':
        res = 'Ha enviado un video';
        break;
      case 'image':
        res = 'Ha enviado una imagen';
        break;
      case 'document':
        res = 'Ha enviado un documento';
    }

    return res;
  }

  approveChat(chatId) {
    return this.restService.makeHttpRequest('chat', 'updateChat', {
      chatId,
      data: {
        is_approved: true
      }
    }, 'post');
  }

  markChatRead(chatId) {
    return this.restService.makeHttpRequest('chat', 'markAsRead', {
      chatId
    }, 'post');
  }

  updateGroupChat(groupId, updateData) {
    return this.restService.makeHttpRequest('chat', 'updateGroup', {
      groupId,
      updateData
    }, 'post');
  }

  deleteChatMessages(chatId, selectedMessages) {
    return this.restService.makeHttpRequest('chat', 'deleteMessages', {
      chatId,
      selectedMessages
    }, 'post');
  }

  reassignContact(chatId, prevAgentId, newAgentId) {
    return this.restService.makeHttpRequest('chat', 'reassignContact', {
      chatId,
      prevAgentId,
      newAgentId
    }, 'post');
  }
}





